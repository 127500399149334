<template>
  <div
    :id="`autocomplete-container-${name}`"
    class="input autocomplete"
    :class="[
      attrClass,
      {
        'min-w-[14em]': multiple,
        'input-error': errorMessage
      }
    ]"
    :style="attrStyle"
  >
    <Combobox
      :model-value="selected"
      as="div"
      :nullable="true"
      :multiple="multiple"
      class="relative"
      :by="compare"
      @update:model-value="updateSelected"
    >
      <label v-if="label" class="label input-label autocomplete-label">
        {{ label }}
      </label>
      <div class="autocomplete-inputContainer relative cursor-default">
        <div
          v-if="multiple"
          class="input-field autocomplete-field min-w-[14em] w-full flex items-center flex-wrap gap-2"
        >
          <span
            v-for="(option, index) in selected"
            :key="index"
            :title="option?.text || option"
            class="chips-primary max-w-[calc(100%-10px)] z-[3]"
          >
            <span class="truncate max-w-full">
              <slot
                name="option"
                v-bind="typeof option === 'object' ? option : { option }"
              >
                {{ option?.text || option }}
              </slot>
            </span>
            <Icon
              :path="mdiClose"
              class="close-icon"
              @click.prevent="removeFromSelected(index)"
            />
          </span>
          <ComboboxInput
            ref="searchInput"
            :display-value="formatterDisplayValues"
            :placeholder="selected && selected.length ? null : placeholder"
            class="autocomplete-hiddenField bg-transparent w-full max-w-[100%] w-min flex-grow"
            :size="inputSize"
            autocomplete="nope"
            v-bind="attrs"
            @change="updateSearch($event.target.value)"
            @keydown.enter="selectOption"
            @keydown.tab="selectOption"
            @keydown.backspace="removeLastOption"
            @blur="open = false"
            @focus="onFocus"
          />
        </div>
        <ComboboxInput
          v-else
          :id="`${name}-autocomplete-input`"
          ref="searchInput"
          :display-value="formatterDisplayValues"
          :placeholder="placeholder"
          class="input-field autocomplete-field w-full"
          autocomplete="nope"
          v-bind="attrs"
          :model-value="search || selected"
          @change="updateSearch($event.target.value)"
          @focus="onFocus"
          @click="onFocus"
          @blur="onBlur"
          @keydown.arrow-down="openOptions"
          @keydown.escape="closeOptions"
        />
        <div class="icons">
          <slot></slot>
          <button
            v-if="(selected || search) && clearable"
            type="button"
            @click="clear"
          >
            <Icon :path="mdiClose" class="clearIcon text-neutral-light w-6" />
          </button>
          <button
            id="autocomplete-button"
            type="button"
            class="flex items-center"
            tabindex="-1"
            :aria-label="$t('common.autocomplete')"
            @mousedown="onButton"
            @blur="onBlur"
          >
            <slot name="button">
              <Icon
                :path="open ? mdiChevronUp : mdiChevronDown"
                :class="[
                  open
                    ? 'autocomplete-fieldIconOpen'
                    : 'autocomplete-fieldIconClosed',
                  'autocomplete-fieldIcon'
                ]"
              />
            </slot>
          </button>
        </div>
      </div>
      <Transition
        enter-active-class="transition duration-[0.21] ease-in-out"
        enter-from-class="transform scale-90 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-[0.1] ease-in-out hide-loader"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-90 opacity-0"
        @after-leave="leave"
      >
        <ComboboxOptions
          v-if="filteredOptions && filteredOptions.length && open"
          static
          class="absolute z-[4] mt-1 max-h-60 min-w-full w-full max-w-full overflow-auto py-1 max-md:text-sm text-base dropdown-menu"
        >
          <div
            v-if="!filteredOptions || !filteredOptions.length"
            class="relative cursor-default select-none py-2 px-4 text-neutral"
          >
            <span class="text-neutral-light">
              {{ $t('common.noResults') }}
            </span>
          </div>
          <div
            v-if="loading"
            class="loader relative cursor-default select-none py-2 px-4 text-neutral"
          >
            <Icon
              :path="mdiLoading"
              class="text-neutral-light h-5 w-5 mx-auto animate-spin"
            />
          </div>

          <ComboboxOption
            v-for="(option, index) in filteredOptions"
            :key="index"
            v-slot="{ selected: optionIsSelected, active }"
            as="template"
            :value="option"
            :disabled="option.disabled"
          >
            <li
              :class="[
                active
                  ? 'autocomplete-optionActive menu-itemActive'
                  : 'autocomplete-optionDefault',
                'autocomplete-option menu-item',
                'relative select-none py-2 !pl-10 pr-4'
              ]"
            >
              <span
                class="block truncate"
                :class="{
                  'opacity-50': option.disabled
                }"
              >
                <slot
                  name="option"
                  v-bind="typeof option === 'object' ? option : { option }"
                >
                  {{ option?.text || option }}
                </slot>
              </span>
              <span
                v-if="optionIsSelected"
                class="absolute inset-y-0 left-0 flex items-center pl-3"
                :class="[
                  active
                    ? 'autocomplete-optionIconActive'
                    : 'autocomplete-optionIconDefault',
                  'autocomplete-optionIcon'
                ]"
              >
                <Icon
                  v-if="optionIsSelected"
                  class="text-primary"
                  :path="mdiCheckBold"
                />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </Transition>
    </Combobox>
    <span v-if="errorMessage" class="input-errorContainer">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts">
import {
  Combobox,
  // ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue';
import {
  mdiCheckBold,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiLoading
} from '@mdi/js';
import { useField } from 'vee-validate';
import type { PropType } from 'vue';

import type { Rule } from '@/composables/use-rules';
import type { SelectOption } from '@/types/common';
import { compareArrays, getSelectorItemValue } from '@/utils';
export default {
  inheritAttrs: false
};
</script>

<script setup lang="ts">
const { class: attrClass, style: attrStyle, ...attrs } = useAttrs();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any;
type Item = Record<string, Value>;

type Options = (string | SelectOption<any>)[] | null;

type OptionsProp =
  | ((search?: string) => Promise<Options>)
  | ((search?: string) => Options)
  | Options;

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [Object, String, Array] as PropType<Value>,
    default: () => null
  },
  search: {
    type: String,
    default: ''
  },
  options: {
    type: [Array, Function] as PropType<OptionsProp>,
    default: () => []
  },
  textCallback: {
    type: Function as PropType<(option: unknown) => string>
  },
  rules: {
    type: Array as PropType<Rule[]>,
    default: () => []
  },
  name: {
    type: String,
    default: () => 'mySelector'
  },
  multiple: {
    type: Boolean,
    default: () => false
  },
  clearable: {
    type: Boolean,
    default: () => false
  },
  text: {
    type: String,
    default: 'text'
  },
  customButtonEmit: {
    type: Boolean,
    default: () => false
  }
});

type Emits = {
  (e: 'update:modelValue', value: Value, oldValue: Value): void;
  (e: 'update:search', value: Value, oldValue: Value): void;
  (e: 'item-selected', item: Item, oldItem: Item): void;
  (e: 'validate', value: boolean | string): void;
  (e: 'close'): void;
  (e: 'click-button', open: boolean): void;
};

const emit = defineEmits<Emits>();

const open = ref(false);

const selected = ref();

const searchInput = ref<InstanceType<typeof ComboboxInput> | null>(null);

const inputSize = computed(() => {
  return search.value.length + 1;
});

const loading = ref(false);

const doneOptions = ref([]);

const debouncedUpdateOptions = debounce(updateOptions, 250);

async function updateOptions() {
  let newOptions: Options | Promise<Options>;

  if (typeof props.options === 'function') {
    newOptions = props.options(search.value);
  } else {
    newOptions = props.options;
  }

  const onDone = (options: Options) => {
    const newOptions = options?.map(option => {
      if (typeof option === 'object') {
        return option;
      }
      return {
        value: option,
        text: option
      };
    });

    if (!compareArrays(doneOptions.value, newOptions)) {
      doneOptions.value = newOptions;
    }

    loading.value = false;
  };

  if (newOptions instanceof Promise) {
    loading.value = true;
    onDone(await newOptions);
  } else {
    onDone(newOptions);
  }
}

const filteredOptions = computed(() => {
  if (
    typeof props.options !== 'function' &&
    search.value &&
    doneOptions.value
  ) {
    return doneOptions.value.filter(option => {
      return option[props.text]
        .toLowerCase()
        .includes(search.value.toLowerCase());
    });
  }
  return doneOptions.value;
});

const {
  errorMessage,
  value: validateValue,
  validate: validateField
} = useField(props.name, useRules(props.rules));

let hasValidated = false;

const validate = async (isExternalCall = false, force = false) => {
  if (!isExternalCall || hasValidated || force) {
    hasValidated = true;
    await validateField();
  }
  if (!isExternalCall) {
    emit('validate', errorMessage.value || false);
  }
  return errorMessage.value || false;
};

watch(
  selected,
  (item, oldItem) => {
    if (props.multiple) {
      const values = item?.map(getSelectorItemValue);
      if (compareArrays(values, props.modelValue)) {
        return;
      }
      validateValue.value = values;
      emit(
        'update:modelValue',
        values,
        (oldItem || []).map(getSelectorItemValue)
      );
    } else {
      const value = getSelectorItemValue(item);
      const oldValue = getSelectorItemValue(oldItem);
      validateValue.value = value;
      if (!props.multiple && value === props.modelValue) {
        return;
      }
      emit('update:modelValue', value, oldValue);
    }
    emit('item-selected', item, oldItem);
  },
  { deep: true }
);

watch(
  () => props.modelValue,
  value => {
    if (props.multiple) {
      const valuesFromSelected = selected.value?.map(getSelectorItemValue);
      if (compareArrays(valuesFromSelected, value)) {
        return;
      }
      if (!Array.isArray(value)) {
        value = value === null || value === undefined ? [] : [value];
      }
      selected.value = value.map((v: Value) => {
        const foundItem = doneOptions.value?.find(o => {
          if (typeof o !== 'object') {
            return o === v;
          }
          return o.value === v;
        });
        return foundItem || v;
      });
    } else {
      if (selected.value && value && selected.value.value === value) {
        return;
      }
      const foundItem = doneOptions.value?.find(o => {
        if (typeof o !== 'object') {
          return o === value;
        }
        return o.value === value;
      });
      selected.value = foundItem || value;
    }
  },
  { immediate: true, deep: true }
);

const search = ref(props.search);

function updateInputValue() {
  if (
    searchInput.value &&
    search.value &&
    searchInput.value?.$el?.value !== search.value
  ) {
    searchInput.value.$el.value = search.value;
  }
}

watch(
  () => props.search,
  (value: string) => {
    if (typeof value === 'string' && search.value !== value) {
      search.value = value;
    }
    updateInputValue();
  }
);

const debouncedEmitSearch = debounce((value, oldValue) => {
  if (typeof value === 'string' && props.search !== value) {
    emit('update:search', value, oldValue);
  }
  if (
    searchInput.value &&
    searchInput.value?.$el?.value !== value &&
    typeof value === 'string' &&
    (value || !selected.value?.value)
  ) {
    searchInput.value.$el.value =
      (!selected.value?.value ? '' : value) || searchInput.value.$el.value;
  }
  debouncedUpdateOptions();
}, 250);

watch(() => search.value, debouncedEmitSearch);

const compare = (a: Value, b: Value) => {
  return getSelectorItemValue(a) === getSelectorItemValue(b);
};

const updateSelected = (value: Value) => {
  selected.value = value;
  search.value = '';
  if (selected.value) {
    setTimeout(() => {
      open.value = false;
      emit('close');
      updateOptions();
    }, 100);
  }
};

const removeFromSelected = (index: number) => {
  selected.value = selected.value.filter((_: Value, i: number) => i !== index);
};

const formatterDisplayValues = (itemsOrItem: Value | Array<Value>) => {
  let text = '';
  if (Array.isArray(itemsOrItem)) {
    if (props.multiple) {
      return '';
    }
    text = itemsOrItem
      .map(item => {
        return item?.[props.text] || item;
      })
      .join(', ');
  } else if (itemsOrItem) {
    text = itemsOrItem?.[props.text] || itemsOrItem;
  }
  return itemsOrItem ? (typeof text === 'string' ? text : text) : search.value;
};

const openOptions = () => {
  if (!open.value) {
    open.value = true;
  }
};

const closeOptions = () => {
  open.value = false;
  emit('close');
  updateOptions();
};

const updateSearch = (value: string) => {
  if (typeof value === 'string' && search.value !== value) {
    search.value = value;
  }

  openOptions();
};

const clear = () => {
  selected.value = null;
  search.value = '';
};

const leave = () => {
  search.value = '';
};

const selectOption = (event: KeyboardEvent) => {
  if (props.options === null && search.value !== '') {
    event.preventDefault();
    selected.value.push(search.value);
    search.value = '';
  }
};

const removeLastOption = (event: KeyboardEvent) => {
  if (search.value === '' && selected.value.length) {
    event.preventDefault();
    selected.value.pop();
  }
};

function onFocus() {
  debouncedUpdateOptions();
  open.value = true;
}

function onBlur(event: FocusEvent) {
  // if event is outside of the component, close the dropdown

  if (
    (event.relatedTarget as HTMLElement)?.closest?.(
      `#autocomplete-container-${props.name}`
    )
  ) {
    return;
  }
  validate();
  open.value = false;
  emit('close');
  updateOptions();
}

function onButton() {
  if (doneOptions.value?.length && open.value && props.customButtonEmit) {
    emit('click-button', open.value);
    setTimeout(() => {
      updateOptions();
    }, 1);
  } else if (!doneOptions.value?.length || !open.value) {
    if (props.customButtonEmit) {
      emit('click-button', true);
    }
    setTimeout(async () => {
      await updateOptions();
      open.value = true;
    }, 1);
  } else {
    open.value = false;
    emit('click-button', false);
    updateOptions();
  }
}

defineExpose({
  validate
});

onMounted(() => {
  updateInputValue();
});
</script>

<style lang="scss" scoped>
.hide-loader .loader {
  display: none;
}
</style>
